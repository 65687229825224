.Home {
  padding: 1em 1em 1em 1em;
  max-width: 500px;
}

.gs-logo {
  width: 155px;
  display: inline-block;
  position: absolute;
  z-index: -99999;
  right: 1em;
}

.header-text {
  max-width: 275px;
  color: #ffffff;
}

.scout-links {
}

.scout-links button {
  min-width: 10em;
}

.scout-links-header {
  color: #fff8f3;
}

.scout-links .p-button {
  background: #29648a !important;
  border-color: #29648a !important;
}

.scout-links .p-button:active {
  background: #29648a !important;
  border-color: #29648a !important;
}

.scout-links .p-button:focus {
  background: #29648a !important;
  border-color: #29648a !important;
}

.scout-links .p-button:hover {
  background: #29648a !important;
  border-color: #29648a !important;
}

.contact-block {
  color: #ffffff;
  opacity: 0.9;
  margin: 0.25em;
  width: 225px;
}

.contact-block a {
  color: #ffffff;
  opacity: 0.9;
  text-decoration: none;
}

.contact-block a:hover {
  text-decoration: underline;
}

.contact-block a:visited {
  color: #ffffff;
  opacity: 0.9;
}

.contact-block a:active {
  color: #ffffff;
  opacity: 0.9;
}

.contact-block .label {
  color: #aaabb8;
  padding-right: 4px;
}
