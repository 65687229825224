.Login {
  padding: 2em 2em 0.5em 2em;
}

.Login .error {
  text-align: center;
  font-weight: 800;
  color: red;
}

.login button {
  padding: 0.5em;
}
